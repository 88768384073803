<template>
    <div class="vx-row">
        <div class="vx-col w-full">
            <vx-card class="text-center" v-if="loading">
                <h1>Loading.. Please Wait..</h1>
            </vx-card>
            <template v-else>
                <vx-card class="text-center mb-base">
                    <vs-row>
                        <vs-col vs-xs="12" vs-md="6" vs-lg="6">
                            <vs-input
                                label="Entity Name"
                                v-model = "entity_copy.name"
                                placeholder = "Enter Name Here"
                                class="w-full mb-3 text-center"
                                :danger="api_errors.name"
                                :danger-text="api_errors.name ? api_errors.name.join(',') : null"
                            />
                            <small class="block text-danger">
                                The name entered here cannot be changed later. Please re-check before saving.
                            </small>
                        </vs-col>
                        <vs-col vs-xs="12" vs-md="6" vs-lg="6">
                            <label class="vs-input--label">Compliances Starts from</label>
                            <SelectDate class="w-full" :selectedDate.sync="entity_copy.compliances_start_from"/>
                            <small class="text-danger" v-if="api_errors.compliances_start_from">
                                {{ api_errors.compliances_start_from[0] }}
                            </small>
                        </vs-col>
                    </vs-row>
                </vx-card>

                <vx-card class="text-center">
                    <h4 class="mb-base">Choose Type of Entity</h4>
                    <vs-row type="flex" vs-justify="center" vs-align="center">
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconBuilding" size="4x" :class="{'text-primary': entity_copy.entity_type == 'company' && entity_copy.is_foreign == false}"  @click="() => {entity_copy.entity_type = 'company'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">Company</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconBalanceScale" size="4x" :class="{'text-warning': entity_copy.entity_type == 'llp' }" @click="() => {this.entity_copy.entity_type = 'llp'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">LLP</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconHandShake" size="4x" :class="{'text-primary': entity_copy.entity_type == 'partnership' }" @click="() => {this.entity_copy.entity_type = 'partnership'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">Partnership</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconUserTie" size="4x" :class="{'text-primary': entity_copy.entity_type == 'individual' }" @click="() => {this.entity_copy.entity_type = 'individual'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">Individual</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconUsers" size="4x" :class="{'text-warning': entity_copy.entity_type == 'huf' }" @click="() => {this.entity_copy.entity_type = 'huf'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">HUF</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconHandHoldingHeart" size="4x" :class="{'text-danger': entity_copy.entity_type == 'trust' }" @click="() => {this.entity_copy.entity_type = 'trust'; this.entity_copy.is_foreign = false }"  />
                            <h4 class="mt-2">Trust</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconBuilding" size="4x" :class="{'text-success': entity_copy.entity_type == 'society' }" @click="() => {this.entity_copy.entity_type = 'society'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">Society</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconPeopleCarry" size="4x" :class="{'text-primary': entity_copy.entity_type == 'aop' }" @click="() => {this.entity_copy.entity_type = 'aop'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">AOP</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconUserFriends" size="4x" :class="{'text-primary': entity_copy.entity_type == 'boi' }" @click="() => {this.entity_copy.entity_type = 'boi'; this.entity_copy.is_foreign = false }" />
                            <h4 class="mt-2">BOI</h4>
                        </vs-col>
                        <vs-col vs-sm="4" vs-xs="12" vs-lg="2" class="text-center mb-base">
                            <font-awesome-icon :icon="getIconGlobe" size="4x" :class="{'text-primary': entity_copy.entity_type == 'company' && entity_copy.is_foreign == true }" @click="() => {this.entity_copy.entity_type = 'company'; this.entity_copy.is_foreign = true }" />
                            <h4 class="mt-2">Foreign</h4>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col>
                            <small class="text-danger" v-if="api_errors.entity_type">
                                {{ api_errors.entity_type[0] }}
                            </small>
                        </vs-col>
                    </vs-row>
                </vx-card>
                <div class="vx-row text-center mt-4">
                    <div class="vx-col w-full">
                        <vs-button color="primary" :disabled="button_disable" class="mx-auto" @click="submit">Next</vs-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {Request} from "../../../utils/requests";
import {faBuilding, faBalanceScale, faHandshake, faUserTie, faHandHoldingHeart, faUsers, faPeopleCarry, faUserFriends, faGlobe} from "@fortawesome/free-solid-svg-icons"

export default {
    props: {
        entity: {
            required: true
        }
    },
    data() {
        return{
            entity_id: null,
            entity_copy: {},
            edit_mode: false,
            loading: true,
            entity_info_mode: false,
            button_disable: false,
            api_errors: {}
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        '$route.params.id': function(id) {
            this.init()
        }
    },
    computed: {
        getIconBuilding: () => faBuilding,
        getIconBalanceScale: () => faBalanceScale,
        getIconHandShake: () => faHandshake,
        getIconUserTie: () => faUserTie,
        getIconHandHoldingHeart: () => faHandHoldingHeart,
        getIconUsers: () => faUsers,
        getIconPeopleCarry: () => faPeopleCarry,
        getIconUserFriends: () => faUserFriends,
        getIconGlobe: () => faGlobe,
        nextBtnDisabled() {
            if((!this.entity_copy.name) || (this.entity_copy.name && this.entity_copy.name.length == 0))
                return true
            if((!this.entity_copy.entity_type) || (this.entity_copy.entity_type && this.entity_copy.entity_type.length == 0))
                return true
            return false
        }
    },
    methods: {
        init() {
            if(this.entity){
                this.loadEntityData(true)
            } else {
                this.loading = false
                this.entity_copy = {
                    name: null,
                    entity_type: null,
                    compliances_start_from: null,
                    is_foreign: false,
                }
            }
        },
        loadEntityData(redirect=false) {
            this.entity_id = this.entity.id
            this.entity_copy = this.entity
            this.edit_mode = true
            this.loading = false
        },
        submit() {
            if(this.button_disable) return
            this.button_disable = true
            let request_method = this.entity_id ? "PUT" : "POST"
            let url = this.$store.state.apiendpoints.data_entity + (
              this.entity_id ? `${this.entity_id}/` : "create/"
            )

            if(this.entity_copy.is_foreign){
                this.entity_copy.entity_type = 'company'
            }

            Request(this, request_method, url, null, this.entity_copy).then((res) => {
                this.entity_copy = res
                this.entity_id = res.id
                this.$vs.notify({
                    time: 4000,
                    title: 'Saved',
                    text: 'Saved Successfully.',
                    color: 'success'
                })
                this.button_disable = false
                this.$router.push({name: 'configure-entity', params: {'id': res.id}})
            }, (err) => {
                if(err.error) {
                    this.api_errors = err.error
                    if(this.api_errors.quota){
                        this.$vs.notify({
                            time: 7000,
                            title: 'Error',
                            text: this.api_errors.quota[0],
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 4000,
                            title: 'Error',
                            text: 'Please fix the errors and try again.',
                            color: 'danger'
                        })
                    }
                } else {
                    this.$vs.notify({
                        time: 4000,
                        title: 'Error',
                        text: 'An Error occurred while processing your request. Please Retry.',
                        color: 'danger'
                    })
                }
                this.button_disable = false
            })
        },
        postSaveCallback(){
            this.$router.push({'name': 'dashboard-analytics'})
        }
    }
}
</script>

<style>
    .text-center input {
        text-align: center;
    }
</style>
